<template>
  <b-card title="">
    <b-row class="d-flex justify-content-between">
      <b-col sm="6" class="mb-1">
        <!-- button on right -->
        <b-input-group>
          <b-form-input
            placeholder="Find user"
            v-model="filter"
            type="search"
            @change="filter == '' ? fetchUsers() : null"
          />
          <b-input-group-append>
            <b-button
              variant="outline-primary"
              :disabled="!filter"
              @click.prevent="fetchUsers()"
            >
              Search
              <b-spinner small v-show="showOverlay" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-overlay
      :show="showOverlay"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-alert
        variant="primary"
        class="mb-0"
        :show="items.length ? false : true"
      >
        <div class="alert-body text-center">{{ alertMessage }}</div>
      </b-alert>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="items"
        :fields="fields"
        v-show="items.length ? true : false"
      >
        <template #cell(userid)="data">
          <a>#{{ data.value }}</a>
        </template>
        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>
        <template #cell(status)="data">
          <b-badge pill :variant="data.value.variant">
            {{ data.value.status }}
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              v-b-tooltip.hover
              title="Delete user"
              variant="outline-danger"
              class="btn-icon rounded-circle"
              @click="showDeleteModal(data.item.userid)"
            >
              <feather-icon
                :id="`${data.item.userid}-trashicon`"
                icon="Trash2Icon"
                size="16"
                class="cursor-pointer"
              />
            </b-button>
            <b-button
              v-b-tooltip.hover
              title="Edit user"
              variant="outline-warning"
              class="btn-icon rounded-circle"
              @click="showEditFormModal(data.item.userid)"
            >
              <feather-icon icon="ToolIcon" size="16" class="cursor-pointer" />
            </b-button>
            <b-button
              v-b-tooltip.hover
              :title="`${
                data.item.status.status == 'lock' ? 'Unlock User' : 'Lock User'
              }`"
              :variant="`outline-${data.item.status.variant}`"
              size="12"
              class="btn-icon rounded-circle"
              @click="
                `${
                  data.item.status.status == 'lock'
                    ? showUnlockModal(data.item.userid)
                    : showLockModal(data.item.userid)
                }`
              "
            >
              <feather-icon
                :icon="`${
                  data.item.status.status == 'lock' ? 'LockIcon' : 'UnlockIcon'
                }`"
                size="16"
                class="cursor-pointer"
              />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          class="d-flex align-items-center mb-0 mt-1 text-nowrap"
          :label="items.length ? 'Page' : ''"
          label-cols="3"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
        >
          <b-form-select
            class="ml-1"
            id="perPageSelect"
            v-model="currentPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="fetchUsers()"
            v-show="items.length ? true : false"
          />
        </b-form-group>
      </b-card-body>
    </b-overlay>

    <!-- modal login-->
    <b-modal
      id="modal-form-edit-account"
      ref="modal"
      title="Edit user"
      @hidden="resetEditForm"
      @ok="validateEditForm"
      cancel-variant="outline-danger"
      ok-title="Update"
      cancel-title="Cancel"
      centered
    >
      <!-- form -->
      <validation-observer ref="userEditForm">
        <b-form class="mt-2" @submit.prevent>
          <!-- username -->
          <b-form-group label="Username" label-for="username">
            <validation-provider
              #default="{ errors }"
              name="Username"
              rules="required|min:5"
            >
              <b-form-input
                id="username"
                v-model="editForm.username"
                :state="errors.length > 0 ? false : null"
                name="username"
                placeholder="john"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- email -->
          <b-form-group label="Email" label-for="user-email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                id="user-email"
                v-model="editForm.email"
                :state="errors.length > 0 ? false : null"
                name="user-email"
                placeholder="john@example.com"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- password -->
          <b-form-group label="New Password" label-for="user-password">
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="user-password"
                  v-model="editForm.password"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  type="password"
                  name="user-password"
                  placeholder="············"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import app from "@/libs/app";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import useJwt from "@/auth/jwt/useJwt";
import {
  BOverlay,
  VBTooltip,
  BTable,
  BAvatar,
  BBadge,
  BCard,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BRow,
  BCol,
  BSpinner,
  BAlert,
} from "bootstrap-vue";

export default {
  components: {
    BOverlay,
    BCol,
    BRow,
    BTable,
    BAvatar,
    BCard,
    BBadge,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    VBTooltip,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BAlert,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showOverlay: true,
      alertMessage: "Please wait..",
      currentPage: 1,
      pageOptions: [1],
      pageAction: "list",

      filter: null,
      required,
      email,
      editForm: {
        id: "",
        username: "",
        email: "",
        password: "",
      },
      fields: [
        { key: "userid", label: "Id", sortable: false },
        { key: "username", label: "Username", sortable: false },
        { key: "email", label: "Email", sortable: false },
        { key: "balance", label: "Balance", sortable: false },
        { key: "status", label: "Status", sortable: false },
        "actions",
      ],
      /* eslint-disable global-require */
      items: [],
    };
  },
  computed: {},
  created() {
    useJwt.setAction("user");
    this.pageAction = "list";
    this.fetchUsers();
  },
  mounted() {},
  methods: {
    showDeleteModal(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to delete this user permanently.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value != null && value == true) {
            this.deleteaccount(id);
          }
        });
    },
    showLockModal(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to lock this user permanently.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "warning",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value != null && value == true) {
            this.lockaccount(id);
          }
        });
    },
    showUnlockModal(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to unlock this user.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "success",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value != null && value == true) {
            this.unlockaccount(id);
          }
        });
    },
    showEditFormModal(id) {
      this.resetEditForm();
      this.items.forEach((element) => {
        if (id == element.userid) {
          this.editForm.userid = element.userid;
          this.editForm.username = element.username;
          this.editForm.email = element.email;
          this.editForm.password = "";
        }
      });
      this.$bvModal.show("modal-form-edit-account");
    },
    validateEditForm(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Validate Form
      this.$refs.userEditForm.validate().then((success) => {
        if (success) {
          // Hide the modal manually
          this.$nextTick(() => {
            this.editaccount();
          });
        }
      });
    },
    async fetchUsers() {
      this.items.splice(0);
      this.showOverlay = true;
      this.alertMessage = "Please wait..";
      useJwt.setAction("user");
      this.$http
        .post("/app", {
          marker: this.currentPage,
          "list-size": 50,
          search: this.filter,
        })
        .then((response) => {
          let userData = response.data;
          this.pageOptions.splice(0);
          for (var i = 0; i < userData.data.maxpage; i++) {
            this.pageOptions.push(i + 1);
          }
          this.items.splice(0);
          for (let x = 0; x < userData.data.items.length; x++) {
            userData.data.items[x]["actions"] = "";
            this.items.push(userData.data.items[x]);
          }
          if (this.items.length == 0) {
            this.alertMessage = "No results found.";
          }
          this.currentPage = userData.page;
          this.showOverlay = false;
        });
    },
    async deleteaccount(userid) {
      useJwt.setAction("deleteUser");
      this.$http
        .post("/app", {
          user: userid,
        })
        .then((response) => {
          if (response.data.result) {
            for (let x = 0; x < this.items.length; x++) {
              if (this.items[x].userid == userid) {
                this.items.splice(x, 1);
              }
            }
            if (this.items.length == 0) {
              this.alertMessage = "No results found.";
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "DeleteIcon",
                variant: "success",
                text: `${response.data.message}`,
              },
            });
          }
          if (!response.data.result) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed",
                icon: "AlertCircleIcon",
                variant: "danger",
                text: `${response.data.message}`,
              },
            });
          }
        });
    },
    async editaccount() {
      useJwt.setAction("updateUser");
      this.$http
        .post("/app", {
          user: this.editForm.userid,
          username: this.editForm.username,
          email: this.editForm.email,
          password: this.editForm.password,
        })
        .then((response) => {
          if (response.data.result) {
            for (let x = 0; x < this.items.length; x++) {
              if (this.items[x].userid == this.editForm.userid) {
                this.items[x].username = this.editForm.username;
                this.items[x].email = this.editForm.email;
              }
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "PencilIcon",
                variant: "success",
                text: `${response.data.message}`,
              },
            });
            this.$bvModal.hide("modal-form-edit-account");
          }
          if (!response.data.result) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed",
                icon: "AlertCircleIcon",
                variant: "danger",
                text: `${response.data.message}`,
              },
            });
          }
        });
    },
    async lockaccount(userid) {
      useJwt.setAction("lockUser");
      this.$http
        .post("/app", {
          user: userid,
        })
        .then((response) => {
          if (response.data.result) {
            for (let x = 0; x < this.items.length; x++) {
              if (this.items[x].userid == userid) {
                this.items[x].status.status = "lock";
                this.items[x].status.variant = "danger";
              }
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "LockIcon",
                variant: "success",
                text: `${response.data.message}`,
              },
            });
          }
          if (!response.data.result) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed",
                icon: "AlertCircleIcon",
                variant: "danger",
                text: `${response.data.message}`,
              },
            });
          }
        });
    },
    async unlockaccount(userid) {
      useJwt.setAction("unlockUser");
      this.$http
        .post("/app", {
          user: userid,
        })
        .then((response) => {
          if (response.data.result) {
            for (let x = 0; x < this.items.length; x++) {
              if (this.items[x].userid == userid) {
                this.items[x].status.status = "active";
                this.items[x].status.variant = "success";
              }
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "UnlockIcon",
                variant: "success",
                text: `${response.data.message}`,
              },
            });
          }
          if (!response.data.result) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed",
                icon: "AlertCircleIcon",
                variant: "danger",
                text: `${response.data.message}`,
              },
            });
          }
        });
    },
    resetEditForm() {
      this.editForm.userid = "";
      this.editForm.username = "";
      this.editForm.email = "";
      this.editForm.password = "";
    },
  },
};
</script>
